import "./DownloadExport.css";

import { Button } from "@mui/material";
import GetAppIcon from "@mui/icons-material/GetApp";
import React from "react";
import { exportToExcel } from "../../../api/excel";

function DownloadExport({ data, fileName, type }) {
  return (
    <div class="downloadExport__container">
      <Button
        onClick={() => exportToExcel(data, fileName, type)}
        variant="outlined"
        startIcon={<GetAppIcon />}
        style={{ marginTop: "10px" }}
      >
        Export
      </Button>
    </div>
  );
}

export default DownloadExport;
