import { Box, Stack } from "@mui/material";

import React from "react";

function BoxStack({
  boxWidth = "100%",
  stackDirection = { xs: "column", sm: "row" },
  boxStyle={},
  stackStyle={},
  children,
}) {
  return (
    <Box
      component="form"
      sx={{
        "& .MuiTextField-root": { m: 1, width: boxWidth },
        display: "flex",
        flexDirection: "column",
        alignItems: "left",
        justifyContent: "center",
        ...boxStyle
      }}
      noValidate
      direc
      autoComplete="off"
    >
      <Stack direction={stackDirection} sx={{ marginTop: "15px", ...stackStyle}}>
        {children}
      </Stack>
    </Box>
  );
}

export default BoxStack;
