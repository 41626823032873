import "./Footer.css";

import { Box } from "@mui/material";
import React from "react";
import SocialMedia from "../social-media-handels/SocialMedia";

function Footer() {
  return (
    <Box
      className="footer__container"
      sx={{ justifyContent: { xs: "center", sm: "end" } }}
    >
      <SocialMedia />
    </Box>
  );
}

export default Footer;
