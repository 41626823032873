export const SOCIAL_MEDIA_LINKS = {
  FACEBOOK: "https://www.facebook.com/MangalVidyaPeeth/",
  INSTAGRAM: "https://instagram.com/mangalvidyapeeth?igshid=YmMyMTA2M2Y=",
  YOUTUBE: "https://youtube.com/c/MangalVidyapeeth",
};

/***
 * !NOTE:
 * contact structure
 *
 * {
 *  id: {string} keep it in sequence
 *  purpose: {string} 2-3 words
 *  details: [array of detail]
 * }
 *
 * detail structure
 * {
 *  name: {string} contact person details
 *  phoneNumber: {string} contact person phone number in proper format Ex. +91-79995-23196
 *  email: {string} proper email format Ex. rishabh.14@atma.club
 * }
 */
export const contacts = [
  {
    id: "1",
    purpose: "Course Material",
    details: [
      {
        name: "Manglarthi Saudharm Luhadiya",
        phoneNumber: "+91-81711-12391",
      },
    ],
  },
  {
    id: "2",
    purpose: "MVP General Queries",

    details: [
      {
        name: "Manglarthi Samkit Jain",
        phoneNumber: "+91-82795-59830",
      },
    ],
  },
  {
    id: "3",
    purpose: "Batch/Teacher related queries",

    details: [
      {
        name: "Manglarthi Shaleen Jain",
        phoneNumber: "+91-94797-56410",
      },
      {
        name: "Manglarthi Sulabh Jain",
        phoneNumber: "+91-90243-35159",
      },
    ],
  },
  {
    id: "4",
    purpose: "Technical Support",
    details: [
      {
        name: "Manglarthi Shantanu Jain",
        phoneNumber: "+91-90303-29803",
      },
      {
        name: "ATMA Help Support",
        email: "mvp@atma.club",
      },
    ],
  },
];
