import "./CourseForm.css";

import { Alert, Box, Button, Snackbar, Stack, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import BoxStack from "../custom/box-stack/BoxStack";
import Container from "../custom/container/Container";
import { db } from "../../firebase";
import firebase from "firebase/compat/app";

// import CircularProgress from '@mui/material/CircularProgress';

function CourseForm() {
  const navigate = useNavigate();
  const [courseName, setCourseName] = useState("");
  const [courseNameTrns, setCourseNameTrns] = useState("");
  const [description, setDescription] = useState("");
  const [descriptionTrns, setDescriptionTrns] = useState("");
  const [notes, setNotes] = useState("");
  const [notesTrns, setNotesTrns] = useState("");
  const [msg, setMsg] = useState("");
  const [open, setOpen] = useState(false);
  const [severity, setSeverity] = useState("success");
  // const [asyncSpinner, setSpinner] = useState(false);

  //to edit the existing course
  const { id } = useParams();
  const [editMode, setEditMode] = useState(false);

  useEffect(() => {
    console.log("id from url = ", id);
    loadExistingCourse(id);
  }, []);

  const loadExistingCourse = async (id) => {
    if (id) {
      const snapshot = await db.collection("courses").doc(id).get();

      if (snapshot.empty || snapshot.data() === undefined) {
        setSeverity("error");
        setMsg("This course does not exists");
        setOpen(true);
      } else {
        setEditMode(true);
        const data = snapshot.data();
        console.log(data);
        setCourseName(data.courseName);
        setCourseNameTrns(data.courseNameTrns);
        setDescription(data.description);
        setDescriptionTrns(data.descriptionTrns);
        setNotes(data.notes);
        setNotesTrns(data.notesTrns);
      }
    } else {
      setEditMode(false);
    }
  };

  const buildCourse = (e) => {
    e.preventDefault();
    // setSpinner(true);
    if (!editMode) {
      db.collection("courses")
        .add({
          courseName,
          courseNameTrns,
          description,
          descriptionTrns,
          notes,
          notesTrns,
          timestamp: firebase.firestore.FieldValue.serverTimestamp(),
        })
        .then((success) => {
          setMsg("Course created successfully!");
          setOpen(true);
          setSeverity("success");
        })
        .catch((error) => {
          setMsg("Failed to create the course");
          setOpen(true);
          setSeverity("error");
        });
    } else {
      db.collection("courses")
        .doc(id)
        .set(
          {
            description: description,
            descriptionTrns: descriptionTrns,
            notes: notes,
            notesTrns: notesTrns,
            timestamp: firebase.firestore.FieldValue.serverTimestamp(),
          },
          { merge: true }
        )
        .then((success) => {
          setMsg("Course updated successfully!");
          setOpen(true);
          setSeverity("success");
        })
        .catch((error) => {
          setMsg("Failed to update the course");
          setOpen(true);
          setSeverity("error");
        });
    }
  };

  const clearForm = () => {
    setCourseName("");
    setDescription("");
    setNotes("");
    setCourseNameTrns("");
    setDescriptionTrns("");
    setNotesTrns("");
  };

  return (
    <Container
      heading="Build Course"
      align="center"
      enableHeaderButton={false}
      helperText="As a ATMA content creator, you can design your course here. 😊"
      loading={false}
      handleCancel={() => navigate("/courses")}
      handleSubmit={buildCourse}
      submitButtonText={editMode ? "Update Course" : "Create Course"}
      disableWhen={
        !courseName || !courseNameTrns || !description || !descriptionTrns
      }
    >
      <Snackbar
        open={open}
        autoHideDuration={2000}
        onClose={() => {
          clearForm();
          navigate("/courses");
        }}
      >
        <Alert
          onClose={() => setOpen(false)}
          severity={severity}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {msg}
        </Alert>
      </Snackbar>

      <BoxStack stackDirection="column" boxStyle={{flexDirection: "row"}} stackStyle={{gap: '0px'}}>
        <BoxStack>
          <BoxStack
            boxWidth="25ch"
            stackDirection="column"
            boxStyle={{ flexDirection: "row" }}
          >
            <TextField
              fullWidth
              id="courseName"
              margin="normal"
              label="Course Name"
              placeholder="Course Name"
              variant="outlined"
              value={courseName}
              disabled={editMode}
              onChange={(e) => setCourseName(e.target.value)}
            />
            <TextField
              id="outlined-multiline-flexible"
              label="Description"
              placeholder="Description"
              multiline
              rows={4}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </BoxStack>

          <BoxStack
            boxWidth="25ch"
            stackDirection="column"
            boxStyle={{ flexDirection: "row" }}
          >
            <TextField
              fullWidth
              id="courseName"
              margin="normal"
              label="पाठ्यक्रम का नाम"
              placeholder="पाठ्यक्रम का नाम"
              variant="outlined"
              value={courseNameTrns}
              disabled={editMode}
              onChange={(e) => setCourseNameTrns(e.target.value)}
            />

            <TextField
              id="outlined-multiline-flexible"
              label="विवरण"
              placeholder="विवरण"
              multiline
              rows={4}
              value={descriptionTrns}
              onChange={(e) => setDescriptionTrns(e.target.value)}
            />

            {/* <TextField
            fullWidth={true}
            id="outlined-multiline-flexible"
            label="टिप्पणियाँ"
            placeholder="टिप्पणियाँ"
            multiline
            rows={4}
            value={notesTrns}
            onChange={(e) => setNotesTrns(e.target.value)}
          /> */}
          </BoxStack>
        </BoxStack>
        <BoxStack stackStyle={{marginTop: '-1px'}}   boxWidth="25ch"
            boxStyle={{ flexDirection: "row" }}>
          <TextField
            fullWidth={true}
            id="outlined-multiline-flexible"
            label="Notes"
            placeholder="Private Notes"
            multiline
            rows={4}
            value={notes}
            onChange={(e) => setNotes(e.target.value)}
          />
        </BoxStack>
      </BoxStack>
    </Container>
  );
}

export default CourseForm;
